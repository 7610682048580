// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "project-img.png",
    title: "Portfolio",
    description: "Ofcourse i made my own personal portfolio website.",
    tech_stack: "Reactjs, Css",
    github_url: "https://github.com/bhargavie28/react-portfolio",
    demo_url: "https://bhargavireddy.dev",
  }
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/html5.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/css3.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/javascript.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/react.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/bootstrap.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/sass.svg",
    name: "Scss",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/git.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/responsive.svg",
    name: "Responsive",
  },
];
